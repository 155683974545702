// import apiaudio from "apiaudio";
import "../css/style.scss";
import Crunker from "crunker";
// const apiKey = "94fb8876f3b846db86b9f0e47429bdb3"; // api.audio hasan
// const apiKey = "3d9a1b61f2204d8ebc20519554f1d638"; // api.audio berkant
// const apiKey = "53102b9c42a449e898991ad222d7f212"; //audiostack
const apiKey = "2e9257423afd47e1979d53e58d5f8edc"; // audiostack
const { Audiostack } = require("@aflr/audiostack");
const AS = new Audiostack(apiKey);

//ID
const musicExamples = ["3am", "Curtaincall", "Driftingoff", "Electronic", "Emptyfaces", "Fairytale", "Fuzzboy", "Gogogone"];
const button = document.getElementById("generate"); //button
const playvoice = document.getElementById("playVoiceAuthor"); //button
const playBackgroundMusic = document.getElementById("playBackgroundMusic"); //button
const audio = document.getElementById("audio"); //Button
const playBgMusic = document.getElementById("playBgMusic"); //Audio
const playVoiceAudio = document.getElementById("playVoiceAudio"); //Audio
const errorElement = document.getElementById("error");

//  Parameter Samples
var randomParameter = {
  "{Ort}": ["Augsburg", "Frankfurt", "Düsseldorf", "Berlin", "Stuttgart", "München"],
  "{Adresse}": ["Maximilianstraße", "Pröllstraße", "Adresse3", "Adresse4", "Adresse5", "Adresse6"],
  "{Zeit}": ["Mittag", "Abendessen"],
  "{Filiale}": ["Filiale1", "Filiale2", "Filiale3"],
  "{Marke}": ["Burger King", "Subway", "Dentsu", "Audi", "ComViet"],
};

/* Creates Audio File
 * This is used if the user wants to test intro, main and outro (without excel, 1 audio)
 * button - clicked button
 * loads intro, main & outro text from <textarea> and converts it into an audio file (optional parameter)
 */
function createAudio(button) {
  button.onclick = async () => {
    //Individual User Input Settings
    const voiceAuthor = document.getElementById("selectvoice").value;
    const customerid = document.getElementById("customerid").value;
    const kampagneid = document.getElementById("kampagneid").value;
    const spotduration = document.getElementById("spotduration").value;
    const spotspeed = document.getElementById("spotspeed").value;
    const customMusicFile = document.getElementById("customMusicFile");
    const backgroundMusic = document.getElementById("backgroundmusic").value; //SelectBox

    if (!customerid) return notyf.error("Kunden-ID ist nicht angebeben!");
    if (!kampagneid) return notyf.error("Kampagnen-ID ist nicht angebeben!");

    function exctractParameter(text) {
      text = text.replaceAll("[", "<");
      text = text.replaceAll("]", ">");
      return text;
    }
    var loading = "Audiodatei wird erstellt...";
    //gets content from the tinymce input
    var intro = tinymce.get("intro").getContent({ format: "text" });
    var main = tinymce.get("main").getContent({ format: "text" });
    var outro = tinymce.get("outro").getContent({ format: "text" });

    //connect all sections together
    var scriptText = exctractParameter(`${intro} ${main} ${outro}`);

    /* Checks if [parameter] are available in the input fields and replace them with random samples from => object: randomParameter
     * {{speak|VARIABLE}} is used for custom variables
     * https://docs.api.audio/docs/annotation
     *
     */
    const regex = /\{(.+?)\}/g;
    const found = scriptText.match(regex);

    console.log(found);

    if (found) {
      for (let i = 0; i < found.length; i++) {
        var foundbrace = found[i];
        console.log("Found: " + foundbrace);

        if (foundbrace in randomParameter) {
          const range = randomParameter[foundbrace].length - 1;
          const randomNumber = Math.floor(Math.random() * range);
          var scriptText = scriptText.replace(foundbrace, `${randomParameter[foundbrace][randomNumber]}`);
        } else {
          var replacedString = foundbrace.replace(/[{}]/g, "");
          var scriptText = scriptText.replace(foundbrace, `${replacedString}`);
          console.log(replacedString);
        }
      }
    }

    console.log(scriptText);

    try {
      var resetButtonText = "Audiospot erstellen";
      button.innerText = loading;

      // let script = await AS.Content.Script.create({
      //   scriptText: scriptText,
      //   scriptName: `${customerid}-${kampagneid}`,
      //   projectName: "AudioPlus",
      // });

      // var script = {
      //   scriptId: "dbdc41fc-18a8-413c-9ccc-a96dc0797f4a"
      // }
      // console.log(script);

      // const tts = await AS.Speech.Tts.create({
      //   scriptId: script.scriptId,
      //   voice: voiceAuthor,
      //   speed: parseInt(spotspeed),
      // });
      // console.log("tts",tts);

      // if (customMusicFile.files.length != 0 && !musicExamples.includes(backgroundMusic)) {
      //   //Custom music added
      //   var customMusic = URL.createObjectURL(customMusicFile.files[0]);
      //   var mastering = {
      //     scriptId: script.scriptId,
      //     endFormat: "mp3",
      //   };
      // } else {
      //   //kein custom music
      //   var mastering = {
      //     scriptId: script.scriptId,
      //     endFormat: "mp3",
      //     soundTemplate: backgroundMusic,
      //   };
      // }

      // const mix = await AS.Production.Mix.create({
      //   speechId: tts.speechId,
      //   soundTemplate: backgroundMusic,
      // });
      // console.log("mix", mix);

      // const encode = await AS.Delivery.Encoder.encodeMix({
      //   productionId: mix.productionId,
      //   preset: "mp3_high",
      // });

      const encode = {
          "meta": {
              "version": "2.0.0",
              "requestId": "049565d0-e6fe-4d6d-80c7-ccf9fff5c227",
              "creditsUsed": 5,
              "creditsRemaining": 4748.4024,
              "message": "Audio processed successfully"
          },
          "url": "https://v2.api.audio/file/52ff5a8f-1ed6-4ff0-ab52-7d191afd70ba",
          "format": "mp3"
      };

      console.log("encode", encode);

      var audiourl = await encode.url;
      console.log(audiourl);

      // var audioContext = new AudioContext();
      // // fetch audiofile from url
      // var audioBuffer = await fetch(audiourl+"?apiKey="+apiKey, {
      //   mode: 'no-cors',
      //   headers: {
      //     'x-api-key': apiKey
      //   },
      // })
      //   .then((response) => response.arrayBuffer())
      //   .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer));

      // allow cors from localhost
      const allowedOrigins = ['http://localhost:1234', 'https://audioplus.apprepository.net', 'https://v2.api.audio'];
      var reqOpts = {
        method: 'GET',
        credentials: 'include',
        // mode: 'no-cors',
        headers: {
          'x-api-key': apiKey,
          'Access-Control-Allow-Origin': 'https://audioplus.apprepository.net',
          'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Api-Key, x-api-key',
          'Access-Control-Allow-Credentials': 'true'
        },
      }
      // allow cors
      const response = await fetch(audiourl, reqOpts);
      const audioBuffer = await response.arrayBuffer();
      console.log(audioBuffer);

      //add background musik
      if (customMusic) {
        console.log(customMusic);
        let crunker = new Crunker();
        crunker
          .fetchAudio(audioBuffer, customMusic)
          .then((buffers) => crunker.mergeAudio(buffers))
          .then((merged) => crunker.export(merged, "audio/mp3"))
          .then((output) => {
            // crunker.download(output.blob)
            const convertedAudio = output.url;
            audio.src = convertedAudio;
            audio.title = `${customerid}-${kampagneid}.mp3`;
            audio.onend = function (e) {
              URL.revokeObjectURL(audio.src);
            };
          })
          .catch((error) => {
            throw new Error(error);
          });
      } else {
        // audio.src = audiourl;
        // audio.onend = function (e) {
        //   URL.revokeObjectURL(audio.src);
        // };
      }

      // Reset
      // apiaudio.reset();
      button.innerText = resetButtonText;
      notyf.success("Audio Datei erstellt!");

      // Handle errors
    } catch (e) {
      console.log(e);
      if (e.message) notyf.error(e.message);
      else if (e.errors) notyf.error(e.errors[0]);
      else notyf.error(e);
      // apiaudio.reset();
      button.innerText = resetButtonText;
    }
  };
}

/* Test Author Voice and Background Music
 * button - clicked button
 * audiotag - id of the audio tag element
 */
function playSelectedAudio(button, audiotag) {
  button.onclick = async () => {
    //resets apiaudio connection in case its still connected
    //Individual User Input Settings
    const voiceAuthor = document.getElementById("selectvoice").value;
    const bgMusicFile = document.getElementById("customMusicFile");
    const backgroundMusic = document.getElementById("backgroundmusic").value; //Selext Box
    const customerid = document.getElementById("customerid").value;
    var germanauthors = ["Matthias", "Katja", "Joel", "Stefan"];

    try {
      //switch button icons
      button.classList.remove("fa-circle-play");
      button.classList.add("fa-spinner");
      button.classList.add("fa-spin-pulse");

      // Configure API audio with the API key
      if (germanauthors.includes(voiceAuthor)) {
        var scriptText = `Hey, mein Name ist {{username|${voiceAuthor}}} und das ist meine Stimme.`;
      } else {
        var scriptText = `Hello, my name is {{username|${voiceAuthor}}} and this is my Voice.`;
      }

      let script = await AS.Content.Script.create({ scriptText });

      if (button == playvoice) {
        var audiobj = {
          scriptId: script.scriptId,
          endFormat: "mp3",
        };
      } else {
        if (bgMusicFile.files.length != 0 && !musicExamples.includes(backgroundMusic)) {
          //Custom music added
          var customMusic = URL.createObjectURL(bgMusicFile.files[0]);
          var audiobj = {
            scriptId: script.scriptId,
            endFormat: "mp3",
          };
        } else {
          var audiobj = {
            scriptId: script.scriptId,
            endFormat: "mp3",
            soundTemplate: backgroundMusic,
          };
        }
      }

      await apiaudio.Speech.create({
        scriptId: scriptID,
        scriptName: customerid,
        voice: voiceAuthor,
        speed: "100",
        effect: "volume_boost_high",
      });
      await apiaudio.Mastering.create(audiobj);

      let masteringResult = await apiaudio.Mastering.retrieve(scriptID);
      const audiourl = masteringResult.url;

      //add background musik
      if (customMusic) {
        console.log(customMusic);
        let crunker = new Crunker();
        crunker
          .fetchAudio(audiourl, customMusic)
          .then((buffers) => crunker.mergeAudio(buffers))
          .then((merged) => crunker.export(merged, "audio/mp3"))
          .then((output) => {
            // crunker.download(output.blob)
            const convertedAudio = output.url;
            audiotag.src = convertedAudio;
            audiotag.onend = function (e) {
              URL.revokeObjectURL(audiotag.src);
            };
          })
          .catch((error) => {
            throw new Error(error);
          });
      } else {
        audiotag.src = audiourl;
        audiotag.onend = function (e) {
          URL.revokeObjectURL(audiotag.src);
        };
      }

      // Reset connection & button icons
      button.classList.remove("fa-spinner");
      button.classList.remove("fa-spin-pulse");
      button.classList.add("fa-circle-play");

      // Handle errors
    } catch (e) {
      console.log(e);
      if (e.message) errorElement.innerText = e.message;
      else if (e.errors) errorElement.innerText = e.errors[0];
      else errorElement.innerText = e;
      notyf.error(e);
      button.classList.remove("fa-spinner");
      button.classList.remove("fa-spin-pulse");
      button.classList.add("fa-circle-play");
    }
  };
}

// addNewSection(addVersionIntro);

createAudio(button);
playSelectedAudio(playvoice, playVoiceAudio);
playSelectedAudio(playBackgroundMusic, playBgMusic);
